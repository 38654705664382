.custom-formV2 {
  fieldset {
    border: none;
  }

  &-control {
    label {
      font-family: 'NunitoSans Regular', sans-serif;
      font-size: 18px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #79828b;

      &.required:after {
        content: ' *';
        color: red;
      }
    }

    &-error-text {
      margin-left: 10px;
      margin-top: 0px;
      margin-bottom: 0px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      text-align: left;
      color: red;
    }
  }
}

.InputCheckbox-container {
  label > span {
    margin-left: 5px;
  }
}

.InputRadio-container {
  /* Radio */
  input[type='radio'] {
    background-color: white;
    border-radius: 10px;
    box-shadow: inset 0 1px 1px hsla(0, 0%, 100%, 0.8), 0 0 0 1px rgba(0, 174, 203, 0.8);
    cursor: pointer;
    display: inline-block;
    margin-right: 18px;
    position: relative;
    height: 18px;
    width: 18px;
    -webkit-appearance: none;
    &:after {
      background-color: white;
      border-radius: 25px;
      content: '';
      display: block;
      position: relative;
      top: 4px;
      left: 4px;
      height: 10px;
      width: 10px;
    }
    &:checked:after {
      background-color: #00aecb;
      box-shadow: inset 0 0 0 1px rgba(0, 174, 203, 0.8);
    }
  }

  margin-top: 12px;
  > label {
    margin-right: 39px;
  }

  &-vertical {
    display: flex;
    flex-direction: column;
    margin-left: 29px;
    &:first-child {
      margin-top: 13px;
    }
  }
}

.inputFormItem {
  &-container {
    display: flex;
    width: 100%;
    position: relative;

    &-icon {
      position: absolute;
      padding: 10px;
      color: white;
      min-width: 50px;
      right: 0px;
      height: 68px;
      width: 68px;
      /* content in the icon div is centered, without bootstrap or font-awesome you may wish to add your own text in the span */
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      > img {
        height: auto;
        width: 21px;
      }
    }

    .input {
      font-family: 'NunitoSans Regular', sans-serif;
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid #cfcfcf;
      background-color: #fff;
      font-weight: 600;
      outline: none;
      color: #393d52;
      padding: 16px;
      padding-right: 68px;
      font-size: 20px;
      line-height: 1.7;
    }
  }
}

.InputArea {
  &-container {
    width: 100%;
  }
}

/* InputGroupedCheckbox */
.ec-checkbox-text {
  font-family: 'NunitoSans Regular', sans-serif;
}

.ec-checkbox-text span {
  margin-left: 5px;
}

.custom-formV2 {
  fieldset {
    border: none;
  }

  &-control {
    label {
      font-size: 18px;
      color: #79828b;

      &.required:after {
        content:" *";
        color: red;
      }
    }

    &-error-text {
      margin-left: 10px;
      margin-top: 0px;
      margin-bottom: 0px;
      letter-spacing: .45px;
      text-align: left;
      color: red;
    }
  }
}

label.checkbox > span {
  margin-left:  0.5rem
}

.checkboxContainer > span {
  margin-left:  0.5rem
}

/* www.w3schools.com/howto/howto_css_custom_checkbox.asp */
.radioBtnContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 12px;
  .label {
    margin-left: 2px;
  }

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: white;
    border: 1px solid rgba(0,174,203, 0.8);

    /* Show the indicator (dot/circle) when checked */
    &:after {
      display: block;
    }
  }

  /* When the radio button is disabled, add a grey background */
  input:disabled ~ .checkmark, ~ .label {
    background: #dddddd;
    cursor: not-allowed;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 5px;
    left: 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(0,174,203, 0.8);
  }


  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #eee;
    border-radius: 50%;
    border: 1px solid rgba(0,174,203, 0.8);

    /* Create the indicator (the dot/circle - hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  label {
    margin-right: 39px;
    margin-left: 39px;
  }
}
.inputFormItem {
  &-container {
    display: flex;
    width: 100%;
    position: relative;

    &-icon {
      position: absolute;
      padding: 10px;
      color: white;
      min-width: 50px;
      right: 0px;
      top: 0px;
      height: 100%;
      width: 68px;
      /* content in the icon div is centered, without bootstrap or font-awesome you may wish to add your own text in the span */
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      img {
        height: auto;
        width: 21px;
      }
    }

    .input {
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid #cfcfcf;
      background-color: #fff;
      font-weight: 600;
      outline: none;
      color: #393d52;
      padding: 16px;
      padding-right: 68px;
      font-size: 20px;
      line-height: 1.7;
    }
  }
}

.inputAreaFormItem {
  &-container {
    width: 100%;
  }
}

